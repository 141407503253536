import React, { useState, useEffect } from "react";
import { MessageType } from "./websocketConstants";

import { useWs } from "./WebSocketContext";

function PlayersCounter() {
  const [rank, setRank] = useState({ rank: 1 });
  const { ws } = useWs();

  useEffect(() => {
    if (ws === null) return;
    const handleMessage = (event) => {
      try {
        const data = event.data;
        const messages = data.split("\n").map((message) => {
          return JSON.parse(message);
        });
        messages.forEach((message) => {
          if (message.type === MessageType.STATS_UPDATE) {
            setRank({
              rank: message.message.rank,
              numPlayers: message.message.numPlayers,
            });
          }
        });
      } catch (e) {
        console.log("error " + event.data);
      }
    };
    ws.addEventListener("message", handleMessage);

    return () => ws.removeEventListener("message", handleMessage);
  }, [ws]);
  return (
    <div>
      <p style={{ marginBottom: "0", textAlign: "center", fontSize: "15px" }}>
        RANK
      </p>
      <div className="ticker-box">{rank.rank}</div>
    </div>
  );
}

export default PlayersCounter;
