import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";

import { BrowserRouter, Link } from "react-router-dom";

import NavigationBar from "./Pages/NavigationBar";

import { AuthProvider } from "./contexts/AuthContext";

import { WebSocketProvider } from "./Components/WebSocketContext";

import { CacheProvider } from "./Components/ImagesCache";
import AppRoutes from "./Components/Routes";
import ScrollToTop from "./Components/ScrollToTop";
import { Image } from "react-bootstrap";

function App() {
  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);
  const [isHiddenFooterPage, setIsHiddenFooterPage] = useState(false);
  const footerLogo = process.env.REACT_APP_CDN_IMAGES_PATH + "/footer-logo.png";

  const handleNavbarHeight = (height) => {
    const screenHeight = window.innerHeight - height;
    setAvailableHeight(screenHeight);
  };

  // Function to update the state of isHiddenFooterPage
  const updateIsHiddenFooterPage = (value) => {
    setIsHiddenFooterPage(value);
  };

  return (
    <AuthProvider>
      <WebSocketProvider>
        <CacheProvider>
          <BrowserRouter>
            <ScrollToTop>
              <NavigationBar onNavbarHeight={handleNavbarHeight} />
              <div>
                <div style={{ height: "100%" }}>
                  <AppRoutes
                    updateIsHiddenFooterPage={updateIsHiddenFooterPage}
                  />
                </div>

                {!isHiddenFooterPage && (
                  <footer
                    className="footer-container"
                    style={{ backgroundColor: "#151515", color: "#C1C1C1" }}
                  >
                    <Image
                      style={{
                        width: "230px",
                      }}
                      src={footerLogo}
                      alt="footer-logo"
                    ></Image>
                    <p style={{ fontSize: "18px" }}>
                      261 Bolton Road, Bury, England, BL8 2NZ
                    </p>
                    <div>
                      <Link to="/privacy" className="footer-link">
                        Privacy Policy
                      </Link>
                      <Link to="/terms" className="footer-link">
                        Terms & Conditions
                      </Link>
                    </div>
                  </footer>
                )}
              </div>
            </ScrollToTop>
          </BrowserRouter>
        </CacheProvider>
      </WebSocketProvider>
    </AuthProvider>
  );
}

export default App;
