import { useEffect, useRef } from "react";

const useSoundPlayer = (correctSound, wrongSound) => {
  const audioRef = useRef([]);

  const playCorrect = () => {
    audioRef.current[0].load();
    audioRef.current[0]
      .play()
      .catch((error) => console.error("Error playing sound:", error));
  };

  const playWrong = () => {
    audioRef.current[1].load();
    audioRef.current[1]
      .play()
      .catch((error) => console.error("Error playing sound:", error));
  };

  useEffect(() => {
    audioRef.current[0] = new Audio(correctSound);
    audioRef.current[1] = new Audio(wrongSound);
  }, [correctSound, wrongSound]);

  return {
    playCorrect,
    playWrong,
  };
};

export default useSoundPlayer;
