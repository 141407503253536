import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getUserProfile } = useAuth();

  const { u_id, c_id, bill_id } = location.state ?? {};

  const fee = 0.99;

  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };

  const createOrder = () => {
    if (!c_id || !u_id) {
      navigate("/");
    }
    return fetch("/api/paypal/create-order")
      .then((res) => res.json())
      .then((data) => data.id);
  };

  const onApprove = (data, actions) => {
    const order = data.orderID;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        order_id: order,
        competition_id: c_id,
        user_id: u_id,
        bill_id: bill_id,
      }),
    };
    return fetch("/api/paypal/capture-order", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("Capture result", data);
        getUserProfile(u_id);
        navigate("/game-arena");
        // show success message to user
        // Add Facebook Pixel Purchase event tracking
        window.fbq("track", "Purchase", {
          value: data.amount, // Include the purchase amount here
          currency: "USD", // Set the currency
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Container className="my-5 text-center paypal-btn">
      <Row>
        <Col>
          <p className="text-center">The operation fee is ${fee.toFixed(2)}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              createOrder={createOrder}
              onApprove={onApprove}
              onError={(err) => console.log(err)}
              fundingSource={undefined}
              style={{ layout: "vertical", color: "blue" }}
            ></PayPalButtons>
          </PayPalScriptProvider>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentPage;
