import React, { useCallback, useEffect, useRef, useState } from "react";
export const Countdown = React.memo(({ targetDate, fetchCompetition }) => {
  const intervalRef = useRef(null);

  const calculateCountdown = useCallback(() => {
    const targetTime = new Date(targetDate).getTime();
    const now = Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds(),
      new Date().getUTCMilliseconds()
    );
    const diff = targetTime - now;

    if (diff <= 0) {
      return {
        diff,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return {
      diff,
      days,
      hours,
      minutes,
      seconds,
    };
  }, [targetDate]);

  const [countdown, setCountdown] = useState(calculateCountdown());

  useEffect(() => {
    if (countdown.diff < -2000) {
      clearInterval(intervalRef.current);
      fetchCompetition();
    }

    intervalRef.current = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [fetchCompetition, calculateCountdown, countdown.diff]);

  return (
    <div className="countdown-row">
      <div className="countdown-box">
        <h2>{countdown.hours}</h2>
        <p style={{ fontSize: "12px" }}>Hours</p>
      </div>
      <div className="countdown-box">
        <h2>{countdown.minutes}</h2>
        <p style={{ fontSize: "12px" }}>Min</p>
      </div>
      <div className="countdown-box">
        <h2>{countdown.seconds}</h2>
        <p style={{ fontSize: "12px" }}>Sec</p>
      </div>
    </div>
  );
});
