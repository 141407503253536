export function findUploadedBill(profile) {
  // Check if profile object and bills array exist
  if (profile && Array.isArray(profile.Bills)) {
    // Find the first bill with "uploaded" status
    return profile.Bills.find((bill) => bill.Status === "uploaded") || null;
  }

  // Return null if profile or bills array is missing
  return null;
}
