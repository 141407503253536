import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import {
  generateDefaultImageUrl,
  generateOddImageUrl,
} from "../Components/imageUrlGenerator";
import { useCache } from "./ImagesCache";

const useFetchCompetition = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [competition, setCompetition] = useState(null);
  const [urls, setUrls] = useState([]);

  const { preloadImages } = useCache();

  const fetchCompetition = useCallback(async () => {
    setIsLoading(true);
    setError(false);

    try {
      const response = await axios("/api/get-next-competition");
      console.log("res:" + JSON.stringify(response.data));
      setCompetition(response.data);

      if (response.data !== null && Object.keys(response.data).length > 0) {
        if (response.data.icons) {
          const fileNames = response.data.icons;
          const array = Array.from({ length: 12 }, (_, index) => [
            generateDefaultImageUrl(fileNames[index]),
            generateOddImageUrl(fileNames[index]),
          ]);

          setUrls(array);
          preloadImages(array);
        }
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setIsLoading(false);
  }, [preloadImages]);

  useEffect(() => {
    fetchCompetition();
  }, [fetchCompetition]);

  return {
    isLoading,
    error,
    competition,
    urls,
    fetchCompetition,
  };
};

export default useFetchCompetition;
