import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import "../style/Popup.css";
import data from "../Data/Currencies.json";
import { ClipLoader } from "react-spinners";

const currencyCodes = Object.keys(data);

function Popup(props) {
  const { isOpen, onClose, onSuccess, onError, currentUser } = props;
  const [error, setError] = useState(false);
  const [isUpLoading, setIsUpLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [code, setCode] = useState(currencyCodes[0]);
  const [selectedFile, setSelectedFile] = useState(null);

  if (!isOpen) return null;

  const onUpload = async (amount, code, selectedFile) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("amount", amount); // Add the amount parameter
    formData.append("code", code);
    setIsUpLoading(true);

    try {
      const response = await fetch("/api/bills/upload/" + currentUser?.uid, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        onSuccess();
      } else {
        onError();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      onError();
    } finally {
      cleanForm();
    }
  };

  const handleFileChange = (e) => {
    console.log("file changed");
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = (e) => {
    console.log("amount:" + amount);
    console.log("code" + code);
    console.log("file" + selectedFile);

    if (amount === undefined || amount <= 1 || !selectedFile) {
      setError("Imvalid input");
    } else {
      onUpload(parseInt(amount), code, selectedFile);
    }
  };

  const onClickClose = () => {
    cleanForm();
  };

  const cleanForm = () => {
    setAmount("");
    setSelectedFile(null);
    setCode(currencyCodes[0]);
    setIsUpLoading(false);
    onClose();
  };

  return (
    <div className="overlay">
      <div className="popup">
        {isUpLoading && (
          <div className="spinner-overlay">
            <ClipLoader color={"#d6612e"} loading={isUpLoading} size={50} />{" "}
          </div>
        )}

        <div className="popup-content">
          <h2>New Bill </h2>

          <input
            type="file"
            accept=".pdf,.png,.jpg"
            onChange={(e) => handleFileChange(e)}
            disabled={isUpLoading}
          />
          <div className="sum-block">
            <label>Amount:</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              disabled={isUpLoading}
            />
          </div>
          <div className="sum-block">
            <label>Currency:</label>
            <select
              onChange={(e) => setCode(e.target.value)}
              disabled={isUpLoading}>
              {currencyCodes.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="buttons-container">
            <button
              className="upload-button"
              onClick={handleUpload}
              disabled={isUpLoading}>
              Upload
            </button>
            <button className="cancel-button" onClick={onClickClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
