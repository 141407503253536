import React, { useEffect, useState, useRef } from "react";
import { Col } from "react-bootstrap";
import "./Grid.css";

function Ticker({ handleTimeOver, random, isTimeRunning }) {
  const [time, setTime] = useState(10);
  const timerExpiredRef = useRef(false);

  // Reset the time when random changes and ensure ref is updated
  useEffect(() => {
    // Reset the timer
    setTime(10);

    // Wait for the state update to complete and then reset the ref
    const timeoutId = setTimeout(() => {
      timerExpiredRef.current = false;
      console.log("Time is reset to 10 and timerExpiredRef is set to false.");
    }, 100); // Small delay to ensure state is properly set before ref update

    return () => clearTimeout(timeoutId); // Clean up timeout
  }, [random]); // Ensure time is also a dependency to properly check its value

  // Start the countdown when isTimeRunning is true
  useEffect(() => {
    if (!isTimeRunning) return;

    const intervalRef = setInterval(() => {
      setTime((prevTime) => Math.max(prevTime - 0.1, 0)); // Decrease the time by 0.1 every 100ms
    }, 100);

    return () => {
      clearInterval(intervalRef); // Clean up the interval when the timer stops
    };
  }, [isTimeRunning]);

  // Check if time is zero and trigger handleTimeOver
  useEffect(() => {
    if (time <= 0.01 && !timerExpiredRef.current) {
      timerExpiredRef.current = true; // Prevent multiple calls to handleTimeOver
      console.log("Time is zero, calling handleTimeOver");
      handleTimeOver();
    } else {
      console.log(
        "Current time:",
        time,
        "Timer expired:",
        timerExpiredRef.current
      );
    }
  }, [time, handleTimeOver]);

  return (
    <div>
      <p style={{ marginBottom: "0", textAlign: "center", fontSize: "15px" }}>
        TIME
      </p>
      <div className="ticker-box">{time.toFixed(1)}</div>
    </div>
  );
}

export default Ticker;
