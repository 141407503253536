import React, { memo } from "react";
import { ReactSVG } from "react-svg";
import { Container, Row, Col } from "react-bootstrap";
import "./Grid.css";

const GRID_WIDTH = 6;
const GRID_HEIGHT = 10;

const Grid = memo(({ set, random, selectedCell, handleClick }) => {
  const screenWidth = window.innerWidth;

  const calculateCellWidth = () => {
    return Math.min(screenWidth / 7, 50);
  };

  return (
    <Container>
      {[...Array(GRID_HEIGHT)].map((_, rowIndex) => (
        <Row key={rowIndex}>
          {[...Array(GRID_WIDTH)].map((_, colIndex) => {
            const currentIndex = rowIndex * GRID_WIDTH + colIndex;

            const isSelectedCell =
              selectedCell.cell &&
              rowIndex === selectedCell.cell[0] &&
              colIndex === selectedCell.cell[1];

            const cellStyle = isSelectedCell
              ? selectedCell.type === 1
                ? "correct"
                : "wrong"
              : "square";

            const valueToRender = currentIndex !== random ? set[0] : set[1];

            if (!valueToRender) {
              return null; // Skip rendering if no src value
            }

            return (
              <Col
                key={`${rowIndex}-${colIndex}`}
                style={{
                  width: calculateCellWidth(),
                  height: calculateCellWidth(),
                  padding: "5px",
                }}
                className={`cell ${cellStyle} d-flex align-items-center`}
                onClick={() => handleClick([rowIndex, colIndex])}>
                <ReactSVG
                  src={valueToRender}
                  beforeInjection={(svg) => {
                    svg.setAttribute("style", "width: 100%; height: 100%;");
                  }}
                  renumerateIRIElements={false}
                  wrapper="div"
                  className="svg-container"
                />
              </Col>
            );
          })}
        </Row>
      ))}
    </Container>
  );
});

export default Grid;
