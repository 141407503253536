import React, { useCallback, useContext, useState } from "react";

const WsContext = React.createContext();

export function useWs() {
  return useContext(WsContext);
}

export function WebSocketProvider(props) {
  const [ws, setWs] = useState(null);
  const [connectionStatus, setConnectioStatus] = useState(null);

  const connectWebSocket = useCallback(
    (user_id, nick, competition_id, bill) => {
      console.log("CONNECTING WS");
      const handleOpen = () => {
        console.log("handle open callback " + user_id);
        if (ws === null) return;

        setConnectioStatus(ws.readyState);
      };

      const handleClose = (e) => {
        setConnectioStatus(ws.readyState);

        console.log("Closing socket- ", e.code);
      };
      const handleError = (err) => {
        setConnectioStatus(ws.readyState);

        console.error(
          "Socket encountered error: ",
          err.message,
          "Closing socket"
        );
        ws.close();
        setConnectioStatus(ws.readyState);
      };
      let { host } = window.location;

      const protocol = process.env.NODE_ENV === "development" ? "ws" : "wss";
      const ws = new WebSocket(
        `${protocol}://${host}/socket?uid=${user_id}&cid=${competition_id}&nick=${nick}&bill=${bill}`
      );
      setConnectioStatus(ws.readyState);

      setWs(ws);

      ws.addEventListener("open", handleOpen);
      ws.addEventListener("close", handleClose);

      ws.addEventListener("error", handleError);

      return () => {
        ws.removeEventListener("open", handleOpen);

        ws.removeEventListener("close", handleClose);
        ws.removeEventListener("error", handleError);

        ws.close();
      };
    },
    []
  );

  const value = {
    ws,
    connectWebSocket,
    connectionStatus,
  };

  return (
    <WsContext.Provider value={value}>{props.children}</WsContext.Provider>
  );
}
