import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { MessageType } from "./websocketConstants";
import { useWs } from "./WebSocketContext";

const Tips = () => {
  const [prize, setPrize] = useState(0);
  const { ws } = useWs();

  useEffect(() => {
    console.log("Ws now is " + ws);
    if (ws === null) return;
    const handleMessage = (event) => {
      try {
        const data = event.data;
        const messages = data.split("\n").map((message) => {
          return JSON.parse(message);
        });
        messages.forEach((message) => {
          if (message.type === MessageType.STATS_UPDATE) {
            setPrize(message.message.prize);
          }
        });
      } catch (e) {
        console.log("error " + event.data);
      }
    };
    ws.addEventListener("message", handleMessage);

    return () => ws.removeEventListener("message", handleMessage);
  }, [ws]);

  return (
    <div
      className="pregame-block d-flex justify-content-center flex-column"
      style={{ fontSize: "15px", fontWeight: "bold" }}
    >
      <Card className="mt-4">
        <Card.Body>
          <Card.Text>
            Make sure you're connected and prepared at least 30 seconds before
            the start.
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Tips;
