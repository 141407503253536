import React from "react";
import "./ProgressBar.css";

export default function ProgressBar({ corrects }) {
  const circles = () => {
    const num = 12;
    const circlesArray = [];

    for (let i = 0; i < num; i++) {
      let circleClass;

      if (corrects[i] === 0) {
        circleClass = "miss";
      } else if (corrects[i] === 1) {
        circleClass = "bull";
      }

      circlesArray.push(
        <div className={`circle ${circleClass}`} key={i}></div>
      );
    }

    return circlesArray;
  };

  return (
    <div>
      <p style={{ marginBottom: "0", textAlign: "center", fontSize: "15px" }}>
        SCORE
      </p>
      <div className="progress-bar">{circles()}</div>
    </div>
  );
}
