import React, { useEffect } from "react";
import { Image } from "react-bootstrap";

import NextDraw from "../Components/NextDraw";

import "../style/App.css";
import Logos from "../Components/Logos";
import { Link } from "react-router-dom";
import TrackScrollToBottom from "../Components/TrackScollToBottom";

function Main() {
  const gameplay = process.env.REACT_APP_CDN_IMAGES_PATH + "/gameplay.png";
  const worried = process.env.REACT_APP_CDN_IMAGES_PATH + "/worried.png";
  const winning = process.env.REACT_APP_CDN_IMAGES_PATH + "/winning.png";
  const ugc_explainer =
    process.env.REACT_APP_CDN_IMAGES_PATH + "/ugc-explainer.mp4";

  useEffect(() => {
    function handleVolumeChange() {
      const videoElement = document.querySelector("video");
      if (videoElement && !videoElement.muted && videoElement.volume > 0) {
        // Track ViewContent event when the video is unmuted
        window.fbq("track", "VideoUnmuted");
        console.log("video unmuted");
      }
    }

    function handleVideoFinish() {
      // Track WatchVideo event when user finishes watching the video
      window.fbq("track", "FinishedVideo");
      console.log("video finished");
    }

    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.addEventListener("volumechange", handleVolumeChange);
      videoElement.addEventListener("ended", handleVideoFinish);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("volumechange", handleVolumeChange);
        videoElement.removeEventListener("ended", handleVideoFinish);
      }
    };
  }, []);
  return (
    <div>
      <TrackScrollToBottom />
      <div className="main-top-container">
        <div className="header-container">
          <h1>
            <span className="black-word"> WE PAY</span>
            <br></br>
            YOUR BILLS
          </h1>

          <Link to="/signup">
            <button className="custom-button black"> JOIN NOW</button>
          </Link>
        </div>
        <div className="image-container">
          <video
            controls
            autoPlay
            muted // Add the muted attribute
            preload="auto"
            playsInline
            className="responsive-video"
            poster={ugc_explainer}
          >
            <source src={ugc_explainer} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <NextDraw />

      <Logos />

      <div className="bills-routine-block">
        <Image src={worried} className="worried-img" alt="worried"></Image>

        <div>
          <h1 className="bold-header">MONTHLY BILLS CHAOS</h1>
          <p>
            In a world that can feel a bit chaotic, one thing remains as
            dependable as your morning coffee – those relentless monthly bills.
            But what if we told you that you don’t have to worry about paying
            them anymore?
          </p>
        </div>
      </div>

      <div className="introducing-block">
        <div>
          <h1 className="bold-header">INTRODUCING BILLSFIGHTING</h1>
          <p>
            BillsFighting transforms bill payment into a LIVE 2-minute challenge
            competition. Say goodbye to the usual routine and dive into a
            thrilling game where spotting the odd one out can help you pay off
            those bills. It’s an exciting twist on how you handle your monthly
            expenses!
          </p>
        </div>
        <Image src={gameplay} className="gameplay-" alt="gameplay"></Image>
      </div>

      <div className="the-game-block">
        <div className="row-block ">
          {/* <h1>Spice Up Your Bill Routine</h1>
          <p>
            Tired of the same old monthly bill payments? Discover Billsfighting,
            a thrilling way to settle your bills.
          </p> */}

          <h1>Getting Started</h1>
          <p>
            Sign up, upload your unpaid utility bill, pay the 99-cent fee, and
            jump into the fun. It’s as easy as that! <br></br>
            <br></br>
            <b>Accepted bills: </b>
            Your bill can be from any country and in any language. Just make
            sure it’s up to 500 USD or the equivalent in your currency and
            hasn’t expired. You can hide your name and address, but the due date
            and amount need to be visible. Feel free to use the same bill in
            multiple rounds until it expires.
          </p>
          <h1>Privacy Matters</h1>
          <p>
            We take your privacy seriously. Don’t worry—your personal info is
            safe and secure while you’re having fun with BillsFighting.
          </p>
          <h1>Just spot it</h1>
          <p>
            Get ready to test your speed and sharp eyes! Spot the unique item
            among 12 tables in each round, with only 10 seconds per table. Miss
            a few? No biggie—victory is still within reach!
          </p>
          <h1>Winning </h1>
          <Image src={winning} className="worried-img" alt="worried"></Image>
          <p>
            At BillsFighting, the winner is the one who spots the most odd
            items. You don’t need to nail all 12 tables—there’s always a winner,
            and it could totally be you!
          </p>

          <h1>Getting Paid</h1>
          <p>
            You’ll get the amount of the bill you uploaded for that round, paid
            directly to your PayPal account provided at sign-up. Plus, the next
            10 runners-up score free entry into the next round!
          </p>
          <h1>The Skill Game</h1>
          <p>
            In BillsFighting, it’s all about skill! Your success comes from your
            talent and speed, not luck. Join us today and get in on the
            excitement of turning bills into winnings. Ready to give it a shot?
          </p>
          <h1>Need Help?</h1>
          <p>
            Got questions or need help? We’ve got you covered! Just reach out to
            our support team at support@billsfighting.com, and we’ll make sure
            your BillsFighting experience is smooth and hassle-free.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Main;
