import React, { Component } from "react";

class Privacy extends Component {
  render() {
    return (
      <div className="default-container legal">
        <h1> Overview</h1>
        <p>
          The following describes the overall company Privacy Policy of
          <b> VIRONIC LTD</b>, (referred to below "Vironic", "we", "us" and
          "our"), in its websites, apps and games. We understand how important
          it is to keep your personal information confidential, and discrete,
          especially in today's online world. The importance of your privacy is
          outlined in detail below. This Privacy Policy was created to inform
          you what information we collect when you interact with our customer’s
          (“Advertisers”) websites or apps that use the Vironic Services; as
          well as how that information is collected and used by companies that
          display advertisements("Partners"). The potential customers who visit
          Advertisers' websites that employ our services are referred to as
          "Prospects" throughout this document. Because our Privacy Policy may
          be updated periodically, it is imperative that this document is read
          thoroughly and often.
        </p>
        <h1>Personal and Non-Personal Information Personally</h1>
        <p>
          Related Information ("PRI") is data about you that is personally
          identifiable; this is generally described as your name, email address,
          credit card number and/or any other information that might be
          considered private. The PRI that Vironic collects from you is
          information you supply to us; such as your name, email address, credit
          card number and Vironic account login. That information is maintained
          by Vironic only so that you do not have to enter it each time you
          visit our website to login to your account. Non-Personally Related
          Information ("NPRI") data is gathered by Vironic and Vironic's
          Partners, so it can be used by Vironic for the purpose of online
          re-targeting only. NPRI data collected by Vironic for advertising
          purposes is completely anonymous, and only includes the following
          information:
        </p>
        <ul>
          <li>
            Information about your device, such as the type and model,
            manufacturer, operating system (e.g. iOS or Android), carrier name,
            IP address, mobile browser (e.g. Chrome, Safari), applications using
            the Vironic Services and the version of such applications, and
            identifiers assigned to your device, such as its iOS Identifier for
            Advertising (IDFA), Android Advertising ID, or unique device
            identifier (a number uniquely allocated to your device by your
            device manufacturer).
          </li>
          <br></br>
          <li>
            Log information, including the app or website visited, session
            start/stop time, time zone, and network connection type (e.g., WiFi,
            cellular), and cookie information.
          </li>
          <br></br>
          <li>
            The Geo-location of your device (using GPS or other Geo-location
            data), when location services have been enabled for the mobile app
            or website that uses the Vironic Services.
          </li>
          <li>
            Information about ads served, viewed, or clicked on, such as the
            type of ad, where the ad was served, whether you clicked on it, and
            whether you visited the advertiser’s website or purchased the
            product or service advertised.
          </li>{" "}
          <br></br>
          <li>
            Information that Vironic Customers and other third parties have
            collected and shared with Vironic, such as information about
            activity in their services.
          </li>
          This may include the content you view or searches you made, the
          language you prefer, or other non-personally identifying demographic
          or interest information, to help make the ads served to you more
          relevant.
        </ul>
        <p>
          None of this information, under any circumstances, individually or
          grouped together, can be traced back to a specific individual.
        </p>
        <h1>Cookies</h1>
        <p>
          To those unfamiliar, a cookie is used to store data on a computer's
          hard drive. In today's online world all websites and applications
          utilize cookies to one extent or another. Vironic uses it's cookies to
          provide a Re-targeting service only. A Prospect receives a cookie when
          interacting with Vironic.net and /or our customer’s (“Advertisers”) or
          Partner’s websites or apps that uses the Vironic Services; Part of
          Vironic's business requires that Vironic has no access to, or control
          over Partners’ cookies. However, requires that each Partner only
          collect NPRI information for the use of serving re-targeted ads. Any
          Partner found in violation of this policy will be immediately removed.
        </p>
        <h1>Vironic's Guidelines for the Use of Cookies and NPRI</h1>
        <p>
          Vironic's technology requires the use of cookies so we can promptly
          deliver advertisements to our Advertiser's Prospects on our Partner's
          sites or apps. This form of marketing is designated as Re-targeting;
          this enhances an Advertiser's potential revenue by specifically
          re-targeting those whom are interested in the products. When a
          Prospect visits one of Vironic's Advertiser's websites, an Vironic
          pixel on that site sets an Vironic cookie on the browser of the
          Prospect. Therefore after the cookie is planted when a Prospect visits
          one of Vironic's Partners' websites; our Partner's website will
          identify the cookie on browser of the Prospect and recognize that they
          previously visited our Advertiser's website.<br></br>
          <br></br> The Prospect will then be shown an advertisement from that
          Advertiser. In this instance, Vironic will know that the Prospect
          first visited our Advertiser's website, and then our Partner's
          website. This information is all that is used in reporting our
          Prospects to advertisers and therefore in no way can be used to
          specifically identify an individual.<br></br>
          <br></br> The NPRI data collected by Vironic in the above outlined
          process of Re-targeting will be kept by Vironic's database for the
          period of one year only. After one year all data is routinely
          destroyed. This information is only used for the purpose of
          re-targeting and in no way is personal information. You may Opt-Out of
          Vironic's services with ease by following the instructions listed
          below.
        </p>
        <h1> How to Opt-Out of Vironic's Services</h1>
        <p>
          Opting Out of Vironic's services is easy; however please note that by
          Opting-Out, you will not be able to take full advantage of Re-targeted
          advertising or have your Vironic account login information remembered
          for you. By deleting your cookies, you will disable Vironic as well as
          its Partners ability to deliver ads. However, you must ensure you
          clear your cookies each time you interact with Vironic or one of our
          Advertiser's websites to ensure you are not receiving Re-targeted
          advertisements.{" "}
        </p>
        <h1>IP's</h1>
        <p>
          {" "}
          An Internet Protocol (IP) address is automatically assigned to your
          computer or mobile device every time you establish a connection on the
          Internet. Vironic will use your IP address only to assess problems
          with the server. Your Account Vironic will only access your
          information if when you become an advertiser with Vironic, provide
          comments, submit a problem, or request information from Vironic and
          provide your name or return contact information. Contact information
          provided in connection with problems, comments or requests is used
          only to respond to those problems, comments or requests.
        </p>
        <h1> Security</h1>{" "}
        <p>
          At Vironic, the security of your information is our utmost priority,
          and we take many strict measures in order to protect our data from
          unauthorized access, and unauthorized disclosure or destruction of
          data. Our data is only stored in centers that provide the highest
          quality security for your information. Physical access is strictly
          controlled both at the perimeter and at building ingress points by
          professional security staff utilizing video surveillance, state of the
          art intrusion detection systems, and other electronic means.
        </p>
        <h1> Removing Your Account Information From Our Records</h1>
        <p>
          You may e-mail us at support@vironic.co to remove your contact
          information from our database assuming there are no current pending
          transactions.
        </p>
        <h1>Disclosure of Information to Third Parties</h1>
        <h2>Personal Information</h2>
        <p>
          Vironic will not sell, trade, release or use your information in any
          way that is not strictly outlined in the above document.
        </p>
        <h2> Transactional Information</h2>{" "}
        <p>
          Vironic 's gathered information such as traffic patterns, sales,
          aggregate data regarding general shopping patterns, and any other
          information will only be shared with reputable individuals. This data
          will never contain any Personal information of the users however.
        </p>
        <h1> Legal</h1>
        <p>
          Issues Vironic discloses personal information when required by law,
          judicial process, warrant, subpoena or in the good-faith belief that
          such action is necessary for the following: To conform to the edict of
          the law or comply with a legal process served on Vironic or its
          Website or Services. To protect and defend the property rights of
          Vironic, the Vironic Website, or that of Vironic Users or to act under
          exigent circumstances to protect the personal safety of Vironic Users,
          employees, or the general public.
        </p>
        <h1>Other Sites' Policies</h1>
        <p>
          Vironic has no direct control over, nor does it bear any of the
          responsibility for the privacy policies and/or content of any sites or
          advertisers linked to or on Vironic Services, or the data these sites
          might deposit on your hard drive.
        </p>
        <h1>Your Consent Choice & Contacting Us</h1>
        <p>
          For mobile devices, your iOS mobile device may provide a “Limit Ad
          Tracking” setting. On Android devices, your settings may allow you to
          “Opt out of Interest-Based Ads.” When you have opted out using this
          setting on a device, Vironic will not use in-app information collected
          from that device to infer your interests or serve ads to that device
          that are targeted based on your inferred interests. Your mobile web
          browser may also provide a “Do Not Track” browser setting. When you
          have enabled this setting in your browser, Vironic will not use mobile
          web browsing information from that browser to infer your interests or
          serve ads to that device that are targeting based on your inferred
          interests. Vironic requires its advertising customers to honor opt-out
          preferences expressed using these controls.<br></br>
          <br></br> By visiting the Vironic website, you consent to the
          collection, use and distribution of any personalized data as outlined
          above. If there are any changes to the policy statement you will be
          notified before the continued use of our technology. Although we may
          alter the privacy policy at any time if any changes are made you will
          be notified via email as soon as the adjustment is made.
        </p>
      </div>
    );
  }
}

export default Privacy;
