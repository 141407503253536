import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-000000",
};

TagManager.initialize(tagManagerArgs);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

const root = createRoot(document.getElementById("root"));
root.render(<App />);
