import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";

const ProfilePage = () => {
  const { currentUser, getUserProfile } = useAuth();
  const [email, setEmail] = useState("");
  const [nickName, setNickName] = useState("");
  const [paypal, setPaypal] = useState("");
  const [mobile, setMobile] = useState("");
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser?.email || "");
      setNickName(currentUser?.profile?.Nick || "");
      setPaypal(currentUser?.profile?.Paypal || "");
      setMobile(currentUser?.profile?.Mobile || "");
    }
  }, [currentUser]);

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `/api/users/${currentUser.uid}/update-profile`,
        {
          paypal: paypal,
          mobile: mobile,
          nickName: nickName,
        }
      );
      console.log(res.data);
      setNotification({
        type: "success",
        message: "Profile updated successfully.",
      });

      getUserProfile(currentUser.uid);
    } catch (error) {
      setNotification({
        type: "danger",
        message:
          "An error occurred while updating the profile. Please try again.",
      });

      console.log(error);
    }
  };

  return (
    <div className="default-container">
      <h1>My Profile</h1>
      {notification && (
        <Alert variant={notification.type}>{notification.message}</Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Nick name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your nick name"
            value={nickName}
            onChange={(e) => setNickName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            disabled
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formPaypal">
          <Form.Label>PayPal account</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter PayPal account"
            value={paypal}
            onChange={(e) => setPaypal(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formMobile">
          <Form.Label>Mobile phone</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter mobile phone"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </Form.Group>

        <Button className="custom-button" type="submit">
          Update
        </Button>
      </Form>
    </div>
  );
};

export default ProfilePage;
