import axios from "axios";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { auth } from "../Components/Firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  const getUserProfile = useCallback(async (id) => {
    try {
      const response = await axios.get("/api/get-profile/" + id);

      setCurrentUser((prevState) => ({
        ...prevState,
        profile: response.data,
      }));
    } catch (error) {}
  }, []);

  const createUser = async (id, email) => {
    try {
      await axios.post("/api/create-profile", {
        id,
        email,
      });
    } catch (error) {
      console.log("error creating profile");
    }
  };

  function logout() {
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setLoading(false);
      setCurrentUser(user);

      if (user) {
        await getUserProfile(user.uid);
      }
    });

    return unsubscribe;
  }, [getUserProfile]);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    getUserProfile,
    createUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
