import React, { useState, useContext, useEffect, useCallback } from "react";

const CacheContext = React.createContext();

export function useCache() {
  return useContext(CacheContext);
}

export const CacheProvider = ({ children }) => {
  const [urls, setUrls] = useState([]);

  const preloadImages = useCallback(async (urls, limit) => {
    const checkImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
      });
    };
    await Promise.all(
      urls
        .slice(0, limit)
        .map((innerArray) =>
          Promise.all(innerArray.map((url) => checkImage(url)))
        )
    );
  }, []);

  const value = { urls, preloadImages };

  return (
    <CacheContext.Provider value={value}>{children}</CacheContext.Provider>
  );
};
