import { useEffect } from "react";

function TrackScrollToBottom() {
  useEffect(() => {
    function handleScroll() {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight) {
        // User has scrolled to the bottom of the page
        // Call your tracking function here, for example:
        window.fbq("track", "ViewContent", {
          content_name: "Bottom of Page",
        });
        console.log("scrolled to bottom");
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return null;
}

export default TrackScrollToBottom;
