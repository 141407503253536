export const readyStatus = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export function getStatusDescription(statusCode) {
  const statusName =
    Object.keys(readyStatus)[Object.values(readyStatus).indexOf(statusCode)];
  return statusName
    ? `WebSocket connection is ${statusName}`
    : "Unknown WebSocket connection status";
}

export const MessageType = {
  GAME_START: 0,
  USER_ANSWER: 1,
  USER_FEEDBACK: 2,
  GAME_OVER: 3,
  STATS_UPDATE: 4,
};
