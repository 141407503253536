import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Image } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const NavigationBar = ({ onNavbarHeight }) => {
  const [expanded, setExpanded] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const headerLogo = process.env.REACT_APP_CDN_IMAGES_PATH + "/header-logo.png";

  useEffect(() => {
    setExpanded(false);

    if (location.pathname === "/play" || location.pathname === "/gameplay") {
      setIsHidden(true);
      console.log("BINGO");
    } else {
      setIsHidden(false);
    }
  }, [location]);

  useEffect(() => {
    const navbarHeight = document.querySelector(".navbar").offsetHeight;
    onNavbarHeight(navbarHeight);
  }, [onNavbarHeight]);

  const toggleNavbar = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  async function handleLogout() {
    try {
      await logout();
      setExpanded(!expanded);
      navigate("/");
    } catch {}
  }

  const handleNavigation = (path) => (e) => {
    e.preventDefault();
    setExpanded(false);
    navigate(path);
  };

  return (
    <Navbar
      id="nav"
      expand="md"
      expanded={expanded}
      style={{
        display: isHidden && "none",
      }}>
      <Container className="selector">
        <Navbar.Brand
          style={{
            padding: "0",
            margin: "0",
          }}>
          <Link to="/" className="navbar-brand">
            <Image src={headerLogo} alt="haeader-logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleNavbar}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {currentUser ? (
              <>
                <Link
                  className="nav-link"
                  onClick={handleNavigation("/game-arena")}>
                  Arena
                </Link>
                <Link className="nav-link" onClick={handleNavigation("/bills")}>
                  Bills
                </Link>

                <Link
                  to="/profile"
                  className="nav-link"
                  onClick={handleNavigation("/profile")}>
                  Profile
                </Link>

                <Link className="nav-link" onClick={handleLogout}>
                  Sign Out
                </Link>
              </>
            ) : (
              <>
                <Link className="nav-link" onClick={handleNavigation("/login")}>
                  Log in
                </Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
