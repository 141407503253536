const generateOddImageUrl = (fileName) => {
  const basename = fileName.split(".")[0];
  const extension = fileName.split(".").pop();
  return `${process.env.REACT_APP_CDN_PATH}/${basename}-odd.${extension}`;
};

const generateDefaultImageUrl = (fileName) => {
  return `${process.env.REACT_APP_CDN_PATH}/${fileName}`;
};

export { generateOddImageUrl, generateDefaultImageUrl };
