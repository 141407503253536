import React from "react";

const PageNotFound = () => {
  return (
    <div id="wrapper" className="default-container">
      <img
        style={{ maxWidth: "200px", margin: "auto" }}
        src="https://i.imgur.com/qIufhof.png"
        alt="404"
      />

      <h3 className="text-center">This page could not be found</h3>
    </div>
  );
};

export default PageNotFound;
