import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

export default function Logos() {
  const [logos, setLogos] = useState([
    "engie.svg",
    "watt.svg",
    "nova.svg",
    "cepsa-logo.svg",
    "octopus-energy.svg",
  ]);

  // useEffect(() => {
  //   // Fetch the list of logos from the server
  //   const customHeaders = {
  //     accept: "application/json",
  //     AccessKey: "c8ecc090-b60a-4773-8d39a91f3203-b30c-4521",
  //   };

  //   const url = "https://storage.bunnycdn.com/static-images-storage/logos/";
  //   fetch(url, {
  //     headers: customHeaders,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Assuming the server returns an array of logo file names (e.g., ['logo1.svg', 'logo2.svg', ...])
  //       setLogos([...data]);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching logos:", error);
  //     });
  // }, []);

  return (
    <div className="sponsored-block">
      <div className="logos-row">
        {logos.map((logo, index) => (
          // Assuming logo is the file name of the SVG file
          <ReactSVG
            key={index}
            src={process.env.REACT_APP_CDN_IMAGES_PATH + "/logos/" + logo}
            beforeInjection={(svg) => {
              // Add attributes or modify the SVG as needed here
              svg.setAttribute("width", "100");
              svg.setAttribute("height", "auto");
            }}
            wrapper="span" // You can use a different wrapper element if needed
            className="svg-class" // Add any class you want to the SVG element
            aria-label={`Logo ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
}
