import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import GameArena from "../Pages/GameArenaPage";
import Main from "../Pages/Main";
import PageNotFound from "../Pages/PageNotFound";
import PaymentPage from "../Pages/PaymentPage";
import Privacy from "../Pages/Privacy";
import ProfilePage from "../Pages/ProfilePage";
import Terms from "../Pages/Terms";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import SignUp from "./SignUp";

import BillsPage from "../Pages/BillsPage";

function AppRoutes({ updateIsHiddenFooterPage }) {
  const location = useLocation();

  useEffect(() => {
    const isHiddenFooterPage =
      location.pathname !== "/" &&
      location.pathname !== "/terms" &&
      location.pathname !== "/privacy";
    if (isHiddenFooterPage) {
      updateIsHiddenFooterPage(true);
    } else {
      updateIsHiddenFooterPage(false);
    }
  }, [updateIsHiddenFooterPage, location.pathname]);

  return (
    <Routes>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />

      <Route exact path="/" element={<Main />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />

      <Route
        path="/game-arena"
        element={
          <PrivateRoute>
            <GameArena />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment"
        element={
          <PrivateRoute>
            <PaymentPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/bills"
        element={
          <PrivateRoute>
            <BillsPage />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AppRoutes;
