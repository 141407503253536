import React, { useCallback, useEffect, useState } from "react";
import "../style/NextDraw.css";

import axios from "axios";

function getInitialCountdown() {
  return {
    hoursFirstDigit: "0",
    hoursSecondDigit: "0",
    minutesFirstDigit: "0",
    minutesSecondDigit: "0",
    secondsFirstDigit: "0",
    secondsSecondDigit: "0",
  };
}

function useCountdown(targetDate) {
  const [countdown, setCountdown] = useState(getInitialCountdown());

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date().getTime();
      const timeRemaining = Math.max(new Date(targetDate).getTime() - now, 0);
      if (timeRemaining <= 0) {
        clearInterval(intervalId);
        return;
      }

      const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      const hoursFirstDigit = String(Math.floor(hours / 10)).padStart(1, "0");
      const hoursSecondDigit = String(hours % 10).padStart(1, "0");
      const minutesFirstDigit = String(Math.floor(minutes / 10)).padStart(
        1,
        "0"
      );
      const minutesSecondDigit = String(minutes % 10).padStart(1, "0");
      const secondsFirstDigit = String(Math.floor(seconds / 10)).padStart(
        1,
        "0"
      );
      const secondsSecondDigit = String(seconds % 10).padStart(1, "0");

      setCountdown({
        hoursFirstDigit,
        hoursSecondDigit,
        minutesFirstDigit,
        minutesSecondDigit,
        secondsFirstDigit,
        secondsSecondDigit,
      });
    };

    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, [targetDate]);

  return countdown;
}

export default function NextDraw() {
  const [competition, setCompetition] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const countdown = useCountdown(competition?.date);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get("/api/get-next-competition");

      setCompetition(response.data);

      setIsLoading(false);

      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(); // Call the async function to get the initial competition date
  }, [fetchData]);

  const [animate, setAnimate] = useState(true);

  const onAnimationEnd = () => {
    setAnimate(false);
  };

  return (
    <div className="draw-block">
      <p className="next-round-word">NEXT ROUND: </p>
      <div className="clock-block">
        <p
          className={`typing-animation ${animate ? "animate" : ""}`}
          onAnimationEnd={onAnimationEnd}
        >
          {competition?.date
            ? convertToLocalFormat(competition.date)
            : "------"}
        </p>
      </div>
    </div>
  );
}

// src/utils/utils.js

export function convertToLocalFormat(dateString) {
  const date = new Date(dateString);
  const today = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const formattedHour = hour.toString().padStart(2, "0");
  const formattedMinute = minute.toString().padStart(2, "0");
  const period = hour < 12 ? "AM" : "PM";
  const formatted12Hour = hour % 12 === 0 ? 12 : hour % 12;
  const isToday = date.toDateString() === today.toDateString();
  return isToday
    ? `Today at ${formatted12Hour}:${formattedMinute} ${period}`
    : `${month} ${day} at ${formatted12Hour}:${formattedMinute} ${period}`;
}
