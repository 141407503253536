import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Tips from "../Components/Tips";
import { useAuth } from "../contexts/AuthContext";
import { Countdown } from "./Countdown";
import { useWs } from "./WebSocketContext";

import { findUploadedBill } from "../Heplers/helper";

export default function PreGame({ competition, fetchCompetition }) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { connectWebSocket, connectionStatus, ws } = useWs();
  const [error, setError] = useState("");

  const isSignedUpForCompetition = useCallback(() => {
    if (
      currentUser &&
      currentUser.profile &&
      currentUser.profile.Competitions &&
      competition
    ) {
      return currentUser.profile.Competitions.includes(competition.id);
    }
    return false;
  }, [currentUser, competition]);
  const handleJoin = () => {
    // Redirect to PayPal payment page
    if (!currentUser.profile.Nick || !currentUser.profile.Paypal) {
      setError("Please update your profile first");

      return;
    }

    if (!currentUser || findUploadedBill(currentUser?.profile) === null) {
      setError("Please upload a bill first");
      return;
    }
    navigate("/payment", {
      state: { u_id: currentUser.uid, c_id: competition.id },
    });
  };

  useEffect(() => {
    console.log(
      "WebSocket connection status:",
      connectionStatus,
      "WebSocket readyState !== 0:",
      connectionStatus !== 0
    );
    console.log(findUploadedBill(currentUser?.profile));

    if (
      isSignedUpForCompetition() &&
      connectionStatus !== 0 &&
      connectionStatus !== 1
    ) {
      connectWebSocket(
        currentUser?.uid,
        currentUser?.profile?.Nick,
        competition?.id,
        findUploadedBill(currentUser?.profile)?.ID
      );
    }
  }, [
    isSignedUpForCompetition,
    connectWebSocket,
    ws,
    connectionStatus,
    competition.id,
    currentUser.uid,
    currentUser?.profile?.Nick,
    currentUser?.profile,
  ]);

  const targetDate = useMemo(
    () => new Date(competition.date),
    [competition.date]
  );

  return (
    <Container>
      {error && (
        <Alert className="text-center" variant="danger">
          {error}
        </Alert>
      )}
      <Row>
        <div className="text-center">
          <h1 style={{ color: "black" }}>The next round starts in</h1>
          <Countdown
            targetDate={targetDate}
            fetchCompetition={fetchCompetition}
          />
        </div>
      </Row>
      <Row>
        {isSignedUpForCompetition() ? (
          <>
            <Tips />
          </>
        ) : (
          <>
            <Container className="d-flex flex-column align-items-center justify-content-center mt-5">
              <h3 className="text-center ">Don't miss your chance to win!</h3>

              <button className="custom-button" onClick={handleJoin}>
                JOIN
              </button>
            </Container>
          </>
        )}
      </Row>
    </Container>
  );
}
